<template>
  <div>
    <div class="card-toolbar mb-5">
<!--     -->
        <router-link  v-if="$can('sales_quotations.create')"  to="/sales/sales-quotations/create" class="btn btn-primary font-weight-bolder">
            <v-icon>mdi-plus</v-icon>{{ $t('sales_quotations.add_sales_quotations')}}
        </router-link>
        <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5" >
            <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
        </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
        <div class="card-body">
            <div class="m-form m-form--fit m--margin-bottom-20">
                <div class="row mb-5">

                    <div class="form-group col-md-4 mt-2 mb-2">
                        <label for="sales_quotation_request_code">{{$t('sales_quotations.sales_quotation_code')}}</label>
                        <input v-model="filters.sales_quotation_request_code" type="text" id="sales_quotation_request_code" class="form-control">
                    </div>
                    <div class="form-group col-md-4 mt-2 mb-2">
                        <label for="from_date">{{$t('sales_quotations.from_date')}}</label>
                        <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
                    </div>
                    <div class="form-group col-md-4 mt-2 mb-2">
                        <label for="to_date">{{$t('sales_quotations.to_date')}}</label>
                        <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
                    </div>
                    <div class="form-group col-md-4 mt-2 mb-2">
                        <label>{{$t('sales_quotations.branch')}}</label>
                        <!-- <select name="" id="branch_id" v-model="filters.branch_id" class="custom-select" >
                            <option v-for="row in branches" :value="row.id" :key="row.id">
                                {{ row.name }}
                            </option>
                        </select> -->
                        <multiselect v-model="branch"
                            :placeholder="$t('sales_quotations.branch')"
                            label="name"
                            track-by="id"
                            :options="branches"
                            :multiple="false"
                            :taggable="true"
                            :show-labels="false"
                            :show-no-options="false"
                            :show-no-results="false">
                        </multiselect>
                    </div>
                    <div class="form-group col-md-4 mt-2 mb-2">
                        <label>{{$t('sales_quotations.added_by')}}</label>
                        <!-- <select name="" id="added_by" v-model="filters.user_id" class="custom-select" >
                            <option v-for="row in users" :value="row.id" :key="row.id">
                                {{ row.name }}
                            </option>
                        </select> -->
                        <multiselect
                                v-model="user"
                                :placeholder="$t('added_by')"
                                label="name"
                                track-by="id"
                                :options="users"
                                :multiple="false"
                                :taggable="false"
                                :show-labels="false"
                                :show-no-options="false"
                                :show-no-results="false"
                                @search-change="getUsers($event)">
                        </multiselect>
                    </div>
                    <div class="form-group col-md-4 mt-2 mb-2">
                        <label for="f_status">{{$t('status')}}</label>
                        <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                            <option value="">{{$t('all')}}</option>
                            <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                        </select>
                    </div>

                    
                    <div class="form-group d-inline-flex col-md-6 mt-10">
                        <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                            <span><i class="fas fa-search"></i>
                                <span>{{$t('search')}}</span>
                            </span>
                        </button>
                        <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                            <span><i class="fas fa-trash-restore"></i>
                                <span>{{$t('reset_search')}}</span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card card-custom">
      
      <div class="card-body">
       
          
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">
          <template slot="#" slot-scope="props">
            <span>{{props.index}}</span>
          </template>
            <template slot="status" slot-scope="props">
<!--              -->
                <button v-if="$can('sales_quotations.change_status')" @click="changeStatus(props.row.id, props.row.status)" class="btn btn-secondary m-btn m-btn--icon w-auto">
                    {{$t('change_status')}} ({{ props.row.status_name }})
                </button>
              <button v-else class="btn btn-secondary m-btn m-btn--icon w-auto">
                {{$t('change_status')}} ({{ props.row.status_name }})
              </button>
            </template>

            <template slot="actions" slot-scope="props">
<!--             -->
              <v-btn v-b-tooltip.hover  v-if="$can('sales_quotations.update')" :title="$t('edit')"  icon color="pink" :to="`/sales/sales-quotations/edit/${props.row.id}`">
                <v-icon small class="mr-2 text-info">mdi-pencil</v-icon>
              </v-btn>
<!--             -->
                <v-icon small class="text-danger" v-if="$can('sales_quotations.delete')" v-b-tooltip.hover :title="$t('delete')"   @click="deleteItem(props.row)">mdi-delete</v-icon>
                <v-btn v-b-tooltip.hover :title="$t('view')" icon color="pink" :to="`/sales/sales-quotations/view/${props.row.id}`">
                    <v-icon small class="mr-2">mdi-eye</v-icon>
                </v-btn>
                 <b-dropdown id="dropdown-offset" :text="$t('more_actions')" variant="outline-primary" class="m-2">
                    <router-link class="dropdown-item" v-if="$can('sales_quotations.convert_to_sales_invoice') && (props.row.status == 2 || props.row.status == 5)" :to="`/sales/sales_invoices/create/${props.row.id}/sales_quotation`">
                    {{ $t('sales_quotations.convert_to_sales_invoice') }}
                    </router-link>
                   <div class="dropdown-item d-flex justify-content-between" v-if="$can('sales_quotations.printing_export_pdf')">
                     <label>{{ $t('MENU.invoice_printing') }}</label>
                     <!--                     <label>{{ $t('tax_invoice_printing') }}</label>-->
                     <div class="d-flex justify-content-between">
                       <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('print')" :to="`/print/print-sales-quotations/${props.row.id}/print`">
                         <i class="fas fa-print"></i>
                       </router-link>
                       <router-link target="_blank" class="ml-3 mr-3" v-b-tooltip.hover :title="$t('pdf')" :to="`/print/print-sales-quotations/${props.row.id}/pdf`">
                         <i class="fas fa-file-pdf"></i>
                       </router-link>
                     </div>
                   </div>

                   <!--                    <router-link v-if="$can('sales_quotations.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/print-sales-quotations/${props.row.id}/print`">-->
<!--                    {{ $t('print') }}-->
<!--                    </router-link>-->
<!--                    <router-link v-if="$can('sales_quotations.printing_export_pdf')" target="_blank" class="dropdown-item" :to="`/print/print-sales-quotations/${props.row.id}/pdf`">-->
<!--                    {{ $t('export_pdf') }}-->
<!--                    </router-link>-->
                </b-dropdown>
            </template>
        </v-server-table>

      </div>
    </div>
    <!--end::customer-->
      <b-modal ref="status-modal" hide-footer :title="$t('change_status')">
          <change-status-form @hide-modal="hideModal()"
                :status-list="status_list"
                :url="routeChangeStatus"
                :current-id="innerId"
                :current-status="statusId"
                @handling-data="getDataAfterChangeStatus">
          </change-status-form>
      </b-modal>
  </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService  from "@/core/services/api.service";
    import ChangeStatusFrom from "@/view/content/forms/ChangeStatusForm";

    export default {
        name: "index-sales-quotation-requests",
        components: {'change-status-form': ChangeStatusFrom,},
        data() {
            return {
                mainRoute:'sales/sales_quotations',
                routeChangeStatus : 'sales/sales_quotation/change-status',
                mainRouteDependency: 'base/dependency',

                showAdvancedSearch: false,

                filter: {
                    sortBy: 'id',
                },
                filters: {
                    title: null,
                  sales_quotation_request_code: null,
                    from_date: null,
                    to_date: null,
                    branch_id: null,
                    user_id: null,
                    status: null,
                },
                status_list: [],
                columns: ['#','title', 'create_date',  'sales_quotation_code', 'customer_name', 'added_by', 'status', 'actions'],
                data: [],
                branches: [],
                users: [],
                innerId: null,
                statusId: null,
                branch: null,
                user: null,
            }
        },
        watch: {
            user: function (val) {
                if (val) {
                    this.filters.user_id = val.id;
                } else {
                    this.filters.user_id = null;
                }
            },
            branch: function (val) {
                if (val) {
                    this.filters.branch_id = val.id;
                } else {
                    this.filters.branch_id = null;
                }
            },
        },
        computed: {
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter')+' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading')+"...",
                    },
                    headings: {
                        title: that.$t('sales_quotations.title'),
                        create_date: that.$t('sales_quotations.create_date'),
                      sales_quotation_code: that.$t('sales_quotations.sales_quotation_code'),
                      customer_name: that.$t('sales_quotations.customer'),
                        added_by: that.$t('sales_quotations.added_by'),
                        status: that.$t('status'),
                        actions: that.$t('actions'),
                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: false,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: data.ascending,
                            byColumn: data.byColumn,
                          perPage: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(`${that.mainRoute}`, {..._params});

                    },
                    responseAdapter(resp) {
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    }
                }

            },
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.sales_quotations")}]);
            // this.getUsers();
            this.getBranches();
            this.getStatus();
        },
        methods: {
          openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.title = null;
                this.filters.sales_quotation_request_code = null;
                this.filters.from_date = null;
                this.filters.to_date = null;
                this.filters.branch_id = null;
                this.filters.user_id = null;
                this.filters.status = null;
                this.branch = null;
                this.user = null;


                this.$refs.table.refresh();
            },
            getFetch() {
                this.$refs.table.refresh();
            },

            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },
            getBranches() {
                ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
                    this.branches = response.data.data;
                });
            },
            getUsers(filter) {
                if(filter && filter.length >= 3)
                    ApiService.get(this.mainRouteDependency +"/users", {params:{filter: filter}}).then((response) => {
                        this.users = response.data.data;
                    });
            },
            getStatus() {
                ApiService.get(this.mainRouteDependency + "/status", {params:{type: 'purchases_quotation_requests'}}).then((response) => {
                    this.status_list = response.data.data;
                });
            },
            actionDelete(item){
                ApiService.delete(`${this.mainRoute}/${item.id}`).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            changeStatus(id, status) {
                this.innerId = id;
                this.statusId = status;
                this.showModal();
            },
            showModal() {
                this.$refs['status-modal'].show()
            },
            hideModal() {
                this.$refs['status-modal'].hide();
            },
            getDataAfterChangeStatus() {
                this.innerId = null;
                this.statusId = null;
                this.doFilter();
            },
        },
    };
</script>
